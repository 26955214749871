/* @import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding: 0;
}

/* :where(.css-dev-only-do-not-override-95jhdm).ant-input-outlined:hover {
  border-color: none;
  background-color: none;
} */

.header {
  height: 6vh;
  width: 100%;
  /* width: 430px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(153, 119, 8, 0);
  color: white;
  z-index: 40;
  padding-bottom: 4px;
}

.landing {
  width: 100%;
  height: 100vh;
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}

.loading-overlay {
  position: fixed;
  top: 50;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-text {
  font-size: 24px;
  color: white;
}

.header-section {
  height: 30vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10; /* Ensure header is above other content */
}

.body-section {
  position: absolute;
  top: 25vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-section {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  bottom: 10px;
  width: 100%;
  /* height: 20vh; */
}
.footer-section-cont-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 10px;
  width: 100%;
  /* height: 20vh; */
}
.footer-section-cont-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  width: 100%;
  /* height: 20vh; */
}

.footer-first-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  /* height: 20vh; */
}

.footer-second-cont {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
  /* height: 20vh; */
}
.link-text {
  color: white;
  margin-right: 10px;
}
.landing-logo {
  width: 160px;
}

.hr-sl {
  width: 260px;
  margin: 8px 0;
  margin-top: 15px;
  height: 1px;
  border: none;
  background-image: linear-gradient(
    to right,
    rgb(6, 62, 94),
    #00ccff,
    rgb(6, 62, 94)
  );
}

.landing-text {
  letter-spacing: 5px;
  margin-top: 0px;
  font-size: 16px;
  margin-bottom: 15px;
  color: #00ccff;
}

.contentH1 {
  font-size: 20px;
  font-weight: 500;
  font-family: Helvetica, Arial, sans-serif;
  color: rgba(255, 255, 255, 0.98);
  text-transform: uppercase;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 20px;
  justify-items: center;
  margin-top: 40px;
  position: relative;
  z-index: 1;
  height: 100%;
  width: 300px;
  /* overflow-y: scroll;
    overflow-y: visible; */
}

.card {
  position: relative;
  background-color: transparent;
  border: 1px solid #00ccff;
  border-radius: 15px;
  cursor: pointer;
  transition: box-shadow 0.25s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 2px;
  padding: 20px;
}

.card::before {
  position: absolute;
  width: 1px;
  border: 1px solid #00ccff;
}

.card-time {
  font-size: 18px;
  color: white;
  text-align: center;
}

.card-price {
  font-size: 28px;
  color: #00ccff;
  text-align: center;
  margin-top: 5px;
}

.card-text {
  margin-top: 5px;
  font-size: 12px;
  color: white;
  text-align: center;
}

.card-title {
  margin-top: 20px;
  font-size: 16px;
  color: white;
  text-align: center;
}

.back-button {
  margin-top: 10px;
  /* width: 80px; */
  height: 31px;
  font-size: 14px;
  background-color: white;
  /* border: 1px solid #00ccff; */
  color: black;
  text-align: center;
  /* border: none; */
}

.o-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 340px; */
  margin-top: 40px;
}

.o-title {
  font-size: 20px;
  color: white;
  /* font-weight: 600; */
  text-align: center;
}

.o-package {
  color: #00ccff;
  font-size: 20px;
  font-weight: 800;
  margin-top: 5px;
  margin-bottom: 10px;
}

.o-email {
  color: white;
  font-size: 12px;
}

.o-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.o-input {
  /* width: 90%; */
  border: 1px solid white;
  color: black;
  font-size: 14px;
  background: white;
  text-align: left;
  height: 30px;
}

.o-button {
  height: 30px;
  margin-left: 10px;
  font-size: 12px;
  background-color: #00ccff;
  color: white;
}

.user-container {
  background: none;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-grid {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 2px;
  padding: 10px;
  /* width: 240px; */
}

.grid-logo {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
  /* border: 1px solid #00ccff; */
}

.grid-desc {
  text-align: center;
  color: white;
  font-size: 10px;
  height: 10px;
  margin-bottom: 20px;
}

.tab-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  gap: 20px;
}

.pay-tab {
  color: white;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
}

.pay-tab.clicked {
  color: white;
  font-size: 12px;
  border: 1px solid #00ccff;
  padding: 5px;
  border-radius: 5px;
}

.qr-container {
  height: 200px;
  width: 200px;
  margin-top: 10px;
}

/* 
.outside {
  height: 500px;
} */

.check-payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.check-button {
  border: 1px solid #00ccff;
  background: none;
  color: white;
}

.enter-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* border: 1px solid white; */
  /* border-radius: 15px; */
  /* height: 120px; */
  /* margin-left: 50px; */
  /* width: 200px; */
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* height: 120px; */
}

.code {
  color: white;
  font-size: 60px;
}

.copy {
  height: 40px;
  width: 40px;
}

.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.login-button {
  background-color: #00ccff;
  width: 160px;
}

.status-container {
  margin-top: 40px;
  height: 300px;
  border-radius: 15px;
  border: 1px solid white;
  color: white;
  display: flex;
  flex-direction: row;
}

.status-a {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  margin-top: 20px;
  align-items: flex-end;
  width: 100%;
}

.status-b {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  margin-top: 20px;
  align-items: flex-start;
  width: 100%;
}

.session-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.terms-p {
  /* width: 360px; */
  color: black;
  text-align: justify;
}
.ebarimt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.font-weight-600 {
  font-weight: 600;
}
.ebarimt-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 120px;
  width: 120px;
}
